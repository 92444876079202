import React, { useState, Fragment } from 'react';
import { css } from 'styled-components';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';

// Hooks
import { useBackground } from '../hooks/use-background';

import { getImage } from '~/helpers';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import Hero from '~/components/.base/hero';
import Modal from '~/components/.base/modal';
import {
  HeadingLarge,
  Heading,
  BodyText,
  BodyTextAlt,
  LargeStencil,
} from '~/components/.base/headings';
import { WhiteButton, RedButton } from '~/components/.base/buttons';
import { Container, Grid, FlexBox, BackgroundDiv } from '~/components/.base/containers';
import ContactlessIcon from '~/images/icons/safety/contactless.svg';
import HealthCareIcon from '~/images/icons/safety/health-care.svg';
import FoodSafetyIcon from '~/images/icons/safety/lobster.svg';
import SocialDistanceIcon from '~/images/icons/safety/social-distancing.svg';
import SprayIcon from '~/images/icons/safety/spray.svg';
import HandWashIcon from '~/images/icons/safety/wash-hands.svg';
import './safety.scss';
import { breakpoint } from '../constants/Breakpoint';
import MailingListPopup from '~/components/JoinMailingList/MailingListPopup';

const Safety = ({ data, location }) => {
  const bgImages = useBackground().edges;
  const [modalIndex, setModalIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <Fragment>
      <MailingListPopup />
      <SEO title="Safety and Covid-19" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <div className="safety">
        <LargeStencil
          css={css`
            transform: rotate(90deg);
            top: 6%;
            left: 251px;
            z-index: 4;
            transform-origin: 0 0;
            @media ${breakpoint.maxLg} {
              display: none;
            }
          `}
        >
          Safe Service
        </LargeStencil>
        <Hero
          wrapperClass="safety-hero"
          imageFluid={data.safetyHero.childImageSharp.fluid}
          alt="Safety"
        >
          <div className="hero-details">
            <Container>
              <HeadingLarge>Safe Service is our first priority</HeadingLarge>
              <BodyText>
                Things may look a little different at our restaurants. That’s because Safe Service
                is our first priority. To protect Team Members and Guests, we are doing a lot of
                little things that make a big difference.
              </BodyText>
            </Container>
          </div>
        </Hero>
        <div className="safety-content">
          <BackgroundDiv
            css={css`
              width: 727px;
              left: 50%;
              top: 0;
              z-index: 1;
              transform: rotate(90deg);
              transform-origin: 0;
              @media ${breakpoint.maxMd} {
                /* left: 50%; */
              }
            `}
          >
            <Image fluid={getImage(bgImages, 'blurred_smudge_4@3x')} alt="" />
          </BackgroundDiv>
          <Grid className="safety-grid">
            <div
              css={css`
                z-index: 5;
                position: relative;
                display: grid;
                padding: 0 20px;
                grid-gap: 60px 20px;
                @media ${breakpoint.sm} {
                  grid-template-columns: repeat(6, 1fr);
                  grid-gap: 60px 40px;
                }
                @media ${breakpoint.lg} {
                  grid-gap: 60px 150px;
                }
              `}
            >
              <div className="col-span-2">
                <HandWashIcon />
                <Heading>Hand Washing</Heading>
                <BodyText>We employ high standards for handwashing.</BodyText>
                <FlexBox align="center" className="learn-more">
                  <BodyTextAlt>Learn More</BodyTextAlt>
                  <WhiteButton
                    pd="0"
                    onClick={() => {
                      setModalIndex(0);
                      setModalIsOpen(true);
                    }}
                    w="40px"
                    h="40px"
                  >
                    +
                  </WhiteButton>
                </FlexBox>
              </div>
              <div className="col-span-2">
                <FoodSafetyIcon className="uses-fill" />
                <Heading>Food Safety &amp; Training</Heading>
                <BodyText>Safe, operational excellence - the CML way.</BodyText>
                <FlexBox align="center" className="learn-more">
                  <BodyTextAlt>Learn More</BodyTextAlt>
                  <WhiteButton
                    pd="0"
                    onClick={() => {
                      setModalIndex(1);
                      setModalIsOpen(true);
                    }}
                    w="40px"
                    h="40px"
                  >
                    +
                  </WhiteButton>
                </FlexBox>
              </div>

              <div className="col-span-2">
                <SocialDistanceIcon />
                <Heading>Social distancing</Heading>
                <BodyText>Let's do our part.</BodyText>
                <FlexBox align="center" className="learn-more">
                  <BodyTextAlt>Learn More</BodyTextAlt>
                  <WhiteButton
                    pd="0"
                    onClick={() => {
                      setModalIndex(2);
                      setModalIsOpen(true);
                    }}
                    w="40px"
                    h="40px"
                  >
                    +
                  </WhiteButton>
                </FlexBox>
              </div>
              <div className="col-span-2 col-start-2">
                <SprayIcon />
                <Heading>Cleaning &amp; Sanitization</Heading>
                <BodyText>Your safety is our top priority.</BodyText>
                <FlexBox align="center" className="learn-more">
                  <BodyTextAlt>Learn More</BodyTextAlt>
                  <WhiteButton
                    pd="0"
                    onClick={() => {
                      setModalIndex(3);
                      setModalIsOpen(true);
                    }}
                    w="40px"
                    h="40px"
                  >
                    +
                  </WhiteButton>
                </FlexBox>
              </div>
              <div className="col-span-2">
                <ContactlessIcon />
                <Heading>Contactless Operations</Heading>
                <BodyText>Go contactless with the CML mobile app.</BodyText>
                <FlexBox align="center" className="learn-more">
                  <BodyTextAlt>Learn More</BodyTextAlt>
                  <WhiteButton
                    pd="0"
                    onClick={() => {
                      setModalIndex(4);
                      setModalIsOpen(true);
                    }}
                    w="40px"
                    h="40px"
                  >
                    +
                  </WhiteButton>
                </FlexBox>
              </div>
            </div>
          </Grid>
          <BodyText className="learn-more-bottom-text"></BodyText>
        </div>
        <Modal
          open={modalIsOpen}
          onClose={() => setModalIsOpen(false)}
          ariaLabelledby="safety-modal-title"
          modalMaxWidth="650px"
          top="0"
        >
          <div className="safety-modal">
            <div className="safety-modal-header">
              <Heading id="safety-modal-title">{safetyModalContents[modalIndex].title}</Heading>
            </div>
            <div className="safety-modal-body">
              <FlexBox>
                <div className="icon-reversal-cont">{safetyModalContents[modalIndex].icon}</div>
                <BodyText>{safetyModalContents[modalIndex].text}</BodyText>
              </FlexBox>
            </div>
            <div className="safety-modal-footer">
              <FlexBox justify="flex-end">
                <WhiteButton
                  onClick={() =>
                    setModalIndex(idx => {
                      if (idx === 0) {
                        return safetyModalContents.length - 1;
                      } else {
                        return idx - 1;
                      }
                    })
                  }
                >
                  Previous
                </WhiteButton>
                <RedButton
                  onClick={() =>
                    setModalIndex(idx => {
                      if (idx === safetyModalContents.length - 1) {
                        return 0;
                      } else {
                        return idx + 1;
                      }
                    })
                  }
                >
                  Next
                </RedButton>
              </FlexBox>
            </div>
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};
const safetyModalContents = [
  {
    icon: <HandWashIcon />,
    title: 'Members Hand Wash',
    text: 'Cousins Maine Lobster team members follow all CDC guidelines and recommended practices for handwashing, including washing regularly and often with soap and water for 20 seconds.',
  },
  {
    icon: <FoodSafetyIcon className="uses-fill" />,
    title: 'Food safety team training',
    text: 'Each Cousins Maine Lobster location is held to the highest standards for training and food safety, in conjunction with corporate standards and local health department rules and regulations.  We take pride in doing things the CML way.',
  },
  {
    icon: <SocialDistanceIcon />,
    title: 'Social distancing',
    text: 'At Cousins Maine Lobster restaurants and food trucks, we ask you to do your part to maintain CDC recommended social distancing guidelines. We thank you for doing your part to help keep our communities safe.',
  },
  {
    icon: <SprayIcon />,
    title: 'Frequent Cleaning',
    text: 'At Cousins Maine Lobster, we’ve always kept the highest standards for cleaning and sanitization in our establishments, and we’ve taken those standards and raised them even farther.  We are working hard to provide you a safe and comfortable environment.  If you would ever like a glove, or a surface wiped down or sanitized, just ask a Cousins Maine Lobster team member.',
  },
  {
    icon: <ContactlessIcon />,
    title: 'Contactless operations',
    text: 'Download our mobile app today for contactless ordering available in your area.  Skip the line, order and pay right on your phone, and pick up your order for a contactless experience.',
  },
];
export const query = graphql`
  {
    safetyHero: file(relativePath: { eq: "safety/safety_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Safety;
